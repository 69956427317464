import React, { useState } from 'react';
import styled from 'styled-components';

const Section = styled.div`
  padding: 1rem;
  line-height: 1.8;
  font-size: 1rem;
  color: #333;
`;

const HighlightText = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
`;

const Heading = styled.h4`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.secondary};
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
  display: inline-block;
  padding-bottom: 0.2rem;
`;

const CompactCard = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundLight};
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const OfferText = styled.p`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
`;

const ServicesSection = styled.section`
  text-align: center;
`;

const ServicesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const TitleUnderline = styled.div`
  height: 2px;
  width: 100px;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 0 auto 2rem;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  justify-content: center;
`;

const ServiceCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ServiceContent = styled.div`
  padding: 1.5rem;
`;

const ServiceTitle = styled.h3`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.primary};
`;

const ServiceDescription = styled.p`
  margin-bottom: 1rem;
`;

const ViewMoreButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 1200px;
  max-height: 100vh;
  overflow-y: auto;
`;

const ModalTitle = styled.h2`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const CloseButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
`;

const ServiceDetails = ({ closeModal, title, content }) => (
  <ModalOverlay onClick={closeModal}>
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <ModalTitle>{title}</ModalTitle>
      {content}
      <CloseButton onClick={closeModal}>Close</CloseButton>
    </ModalContent>
  </ModalOverlay>
);

// Individual Service Components
const ThreeDElevation = () => (
  <Section>
    <p>
      <HighlightText>3D Elevation Drawing</HighlightText> is the three-dimensional representation of a certain building or space. It showcases the <HighlightText>exterior design</HighlightText> of structures like houses, restaurants, commercial buildings, parking spaces, and residential properties.
    </p>

    <Heading>Importance of 3D Elevation Drawings</Heading>
    <CompactCard>
      <p>
        The elevated visualization from a 3D drawing plays a vital role in linking <HighlightText>construction</HighlightText> and <HighlightText>architecture</HighlightText>. It offers clarity from multiple angles and heights, helping clients:
      </p>
      <ul>
        <li>Gain a broader and futuristic insight into building aesthetics</li>
        <li>Understand design elements like lights, terrace layouts, and decorative structures</li>
        <li>Visualize complex creative visions with ease</li>
      </ul>
    </CompactCard>

    <Heading>Benefits for Clients</Heading>
    <CompactCard>
      <p>
        3D elevation drawings enhance communication between clients and the design team, addressing confusion about:
      </p>
      <ul>
        <li>Future project appearance</li>
        <li>Budgeting and planning</li>
        <li>Post-design changes based on requirements</li>
      </ul>
      <p>
        These drawings also serve as a powerful tool for property dealers, real estate agents, and developers, attracting in-demand clients and boosting project demand.
      </p>
    </CompactCard>

    <Heading>Our Expertise</Heading>
    <p>
      At <HighlightText>Elegant-E-Space</HighlightText>, we specialize in delivering <HighlightText>end-to-end 3D elevation services</HighlightText> with highly aesthetic designs. For the past half a decade, we have been helping clients achieve:
    </p>
    <CompactCard>
      <ul>
        <li>Maximum clarity for residential and commercial projects</li>
        <li>Customized solutions for futuristic and functional designs</li>
        <li>Seamless collaboration between clients and design teams</li>
      </ul>
    </CompactCard>

    <OfferText>
      <strong>Contact us today</strong> to explore how our 3D elevation drawings can bring your vision to life. Let’s create something extraordinary together!
    </OfferText>
  </Section>
);


const InteriorDesign = () => (
  <Section>
    <p>
      <HighlightText>Interior Design</HighlightText> is the art of enhancing the interior of a building to create a harmonious, functional, and aesthetically pleasing space. It involves:
    </p>

    <Heading>Core Elements</Heading>
    <CompactCard>
      <ul>
        <li><strong>Space Planning:</strong> Efficient layouts and flow</li>
        <li><strong>Color Schemes:</strong> Harmonious hues and tones</li>
        <li><strong>Furniture & Decor:</strong> Style meets functionality</li>
        <li><strong>Textures & Materials:</strong> Adding depth and feel</li>
        <li><strong>Acoustics & Ventilation:</strong> Comfort through design</li>
      </ul>
    </CompactCard>

    <p style={{ marginTop: '1.5rem' }}>
      Interior designers consider factors like <HighlightText>lifestyle</HighlightText>, <HighlightText>budget</HighlightText>, and <HighlightText>personal taste</HighlightText> to craft spaces that:
    </p>

    <Heading>Benefits</Heading>
    <CompactCard>
      <ul>
        <li>Boost productivity in offices</li>
        <li>Foster relaxation at home</li>
        <li>Enhance well-being in healthcare spaces</li>
        <li>Create ambiance in hotels and restaurants</li>
      </ul>
    </CompactCard>

    <Heading>Popular Interior Design Styles</Heading>
    <CompactCard>
      <p><strong>Modern:</strong> Clean lines, minimal ornamentation, functional design</p>
      <p><strong>Minimalist:</strong> Uncluttered, monochromatic themes</p>
      <p><strong>Traditional:</strong> Classic, warm, and ornate</p>
      <p><strong>Mid-Century Modern:</strong> Retro-inspired organic shapes</p>
      <p><strong>Industrial:</strong> Exposed brick and metal accents</p>
      <p><strong>Rustic:</strong> Cozy, natural materials</p>
      <p><strong>Coastal:</strong> Light, airy, blues and whites</p>
      <p><strong>Bohemian:</strong> Eclectic patterns and vibrant colors</p>
      <p><strong>Farmhouse:</strong> Country-inspired charm</p>
      <p><strong>Art Deco:</strong> Luxurious geometric patterns</p>
      <p><strong>Scandinavian:</strong> Simple and functional</p>
      <p><strong>Zen:</strong> Calming and serene</p>
    </CompactCard>

    <Heading>Transitional Interior Design</Heading>
    <p>
      <HighlightText>Transitional Style</HighlightText> is a perfect blend of traditional and modern elements, creating a balanced and timeless space.
    </p>
    <CompactCard>
      <ul>
        <li><strong>Neutral Palette:</strong> Subtle and calming colors</li>
        <li><strong>Classic Furnishings:</strong> With modern twists</li>
        <li><strong>Mixed Materials:</strong> Wood, metal, and glass</li>
        <li><strong>Simple Patterns:</strong> Elegance in textures</li>
        <li><strong>Lighting Fixtures:</strong> Sophisticated and functional</li>
        <li><strong>Minimal Ornamentation:</strong> Focus on simplicity</li>
      </ul>
    </CompactCard>

    <p>
      This style suits those seeking <HighlightText>sophistication</HighlightText>, <HighlightText>timeless appeal</HighlightText>, and <HighlightText>versatility</HighlightText>.
    </p>
    <p><strong>Example:</strong> Pair a traditional armchair with a sleek, modern coffee table.</p>

    <Heading>Why Choose Elegant E Space?</Heading>
    <p>
      At <HighlightText>Elegant E Space</HighlightText>, we continuously strive to customize styles and blend them according to your taste and budget. Whether you’re looking for a luxurious transformation or a subtle enhancement, our expert team is here to deliver.
    </p>

    <OfferText>
      <strong>Book a consultation with us today!</strong><br />
      Enjoy <HighlightText>high discounts</HighlightText> for a limited period of time.
    </OfferText>
  </Section>
);


const VastuConsultation = () => (
  <Section>
    <p>
      <HighlightText>Vastu Shastra</HighlightText> is an ancient Indian philosophy that combines architecture, astrology, and spirituality to create harmonious and balanced living and working spaces. It aligns buildings with natural energies to enhance <HighlightText>well-being, prosperity,</HighlightText> and <HighlightText>happiness</HighlightText>.
    </p>

    <Heading>Key Principles</Heading>
    <CompactCard>
      <p>
        <strong>1. Orientation:</strong> Direction and alignment of spaces.<br />
        <strong>2. Layout:</strong> Optimized space planning.<br />
        <strong>3. Elements:</strong> Balance of earth, water, fire, air, and space.<br />
        <strong>4. Colors:</strong> Strategic use of hues.<br />
        <strong>5. Geometry:</strong> Symmetry and proportions.
      </p>
    </CompactCard>

    <p style={{ marginTop: '1.5rem' }}>
      At <HighlightText>Elegant E Space</HighlightText>, we help you unlock harmony and prosperity by providing expert Vastu services tailored to your needs.
    </p>

    <Heading>Our Services</Heading>
    <CompactCard>
      <ul>
        <li>Personalized Vastu guidance for homes and offices</li>
        <li>Design packages aligned with Vastu principles</li>
        <li>Practical tips to improve relationships, health, and wealth</li>
      </ul>
    </CompactCard>

    <Heading>Would You Like To</Heading>
    <CompactCard>
      <p>
        - Change the <HighlightText>design/layout</HighlightText>?<br />
        - Modify the <HighlightText>text</HighlightText>?<br />
        - Add or remove <HighlightText>elements</HighlightText>?
      </p>
    </CompactCard>

    <OfferText>
      Book Your Consultation Now and Get <HighlightText>20% Off</HighlightText> Your First Consultation!
    </OfferText>
  </Section>
);


const RealEstateBuilders = () => (
  <Section>
    <p>
      <HighlightText>Real estate</HighlightText> refers to property consisting of land and the buildings on it, along with natural resources such as crops, minerals, and water. It can be classified into several types:
    </p>

    <Heading>Types of Real Estate</Heading>
    <CompactCard>
      <p><strong>1. Residential Real Estate:</strong> Homes, apartments, and properties for individual or family living.</p>
      <p><strong>2. Commercial Real Estate:</strong> Properties for business purposes, such as offices, shopping centers, and restaurants.</p>
      <p><strong>3. Industrial Real Estate:</strong> Factories, warehouses, and logistics properties.</p>
      <p><strong>4. Agricultural Real Estate:</strong> Farms, ranches, and agricultural land.</p>
      <p><strong>5. Investment Real Estate:</strong> Properties for rental income or profit through sales.</p>
    </CompactCard>

    <Heading>Key Aspects of Real Estate</Heading>
    <CompactCard>
      <ul>
        <li>Buying and selling properties</li>
        <li>Property development and construction</li>
        <li>Property management and maintenance</li>
        <li>Real estate marketing and sales</li>
        <li>Real estate finance and mortgage banking</li>
      </ul>
    </CompactCard>

    <p style={{ marginTop: '1.5rem' }}>
      <HighlightText>Builders</HighlightText> specialize in constructing homes and residential properties, focusing on new builds, renovations, and remodeling.
    </p>

    <Heading>Builder Responsibilities</Heading>
    <CompactCard>
      <ul>
        <li><strong>Planning & Design:</strong> From blueprints to final approvals.</li>
        <li><strong>Site Preparation:</strong> Excavation, foundations, and framing.</li>
        <li><strong>Systems Installation:</strong> Electrical, plumbing, and HVAC.</li>
        <li><strong>Finishing Work:</strong> Drywall, flooring, cabinets, and countertops.</li>
        <li><strong>Final Inspections:</strong> Ensuring compliance with local codes.</li>
      </ul>
    </CompactCard>

    <Heading>Additional Services Offered by Builders</Heading>
    <CompactCard>
      <p>
        Some builders offer extended services such as land acquisition, custom design, project management, budgeting, and sourcing high-quality materials, all while ensuring regulatory compliance.
      </p>
    </CompactCard>

    <Heading>Why Choose Elegant-E-Space?</Heading>
    <p>
      At <HighlightText>Elegant-E-Space</HighlightText>, we aim to set a benchmark in the construction and design industry by providing our clients with <HighlightText>BEST IN CLASS</HighlightText> properties and exceptional quality. We take pride in bridging the gap between dreams and reality with:
    </p>
    <CompactCard>
      <ul>
        <li>Premium residential projects</li>
        <li>Luxury properties at affordable costs</li>
        <li>Client-focused convenience and design excellence</li>
      </ul>
    </CompactCard>

    <OfferText>
      <strong>Let us help you achieve your dream home!</strong><br />
      Contact us today to learn more about our premium services.
    </OfferText>
  </Section>
);


const InteriorDecoration = () => (
  <Section>
    <p>
      <HighlightText>Interior Decoration</HighlightText> is the process of uplifting the aesthetic appeal of a space using various elements to create a functional and beautiful environment.
    </p>

    <Heading>Key Elements of Interior Decoration</Heading>
    <CompactCard>
      <p><strong>1. Color:</strong> Paint, fabrics, furniture finishes</p>
      <p><strong>2. Lighting:</strong> Natural, artificial, and ambiance lighting</p>
      <p><strong>3. Textures:</strong> Fabrics, materials, and patterns</p>
      <p><strong>4. Furniture:</strong> Style, arrangement, and functionality</p>
      <p><strong>5. Accessories:</strong> Rugs, artwork, and decorative items</p>
      <p><strong>6. Patterns:</strong> Fabrics, wallpaper, and flooring</p>
    </CompactCard>

    <Heading>Popular Decoration Styles</Heading>
    <CompactCard>
      <ul>
        <li><strong>Modern:</strong> Sleek, clean lines and minimal ornamentation</li>
        <li><strong>Traditional:</strong> Classic designs with warm tones</li>
        <li><strong>Minimalist:</strong> Simple, uncluttered, and monochromatic</li>
        <li><strong>Rustic:</strong> Cozy designs with natural materials</li>
        <li><strong>Coastal:</strong> Light, airy spaces with blues and whites</li>
        <li><strong>Bohemian:</strong> Vibrant colors and eclectic patterns</li>
      </ul>
    </CompactCard>

    <Heading>Goals of Interior Decoration</Heading>
    <CompactCard>
      <ul>
        <li>Reflect personal taste</li>
        <li>Create an inviting ambiance</li>
        <li>Enhance functionality of the space</li>
        <li>Boost mood and comfort</li>
      </ul>
    </CompactCard>

    <p style={{ marginTop: '1.5rem' }}>
      At <HighlightText>Elegant E Space</HighlightText>, our interior decoration services transform your space into a stunning reflection of your unique style. Whether it’s a <HighlightText>commercial</HighlightText>, <HighlightText>domestic</HighlightText>, or <HighlightText>recreational</HighlightText> project, we tailor our approach to meet your vision.
    </p>

    <Heading>Let’s Collaborate</Heading>
    <CompactCard>
      <p>Would you like to:</p>
      <ul>
        <li>Explore specific decoration styles?</li>
        <li>Discuss exciting project ideas?</li>
      </ul>
    </CompactCard>

    <OfferText>
      <strong>Book a consultation</strong> or schedule a site visit at your convenience.<br />
      Let’s create something exceptional together.
    </OfferText>
  </Section>
);


const ResidentialConstruction = () => (
  <Section>
    <p>
      <HighlightText>Residential home construction</HighlightText> is a multi-stage process that transforms an idea into a tangible home. From planning to completion, every stage requires attention to detail and professional execution. Here’s an overview of the procedure:
    </p>

    <Heading>Pre-Construction Stage</Heading>
    <CompactCard>
      <ol>
        <li><strong>Planning and Budgeting:</strong> Define project scope, budget, and timeline.</li>
        <li><strong>Design and Architecture:</strong> Create a custom design or choose a pre-designed plan.</li>
        <li><strong>Obtain Necessary Permits:</strong> Secure approvals from local authorities.</li>
        <li><strong>Site Selection and Preparation:</strong> Purchase land, clear debris, and prepare the site.</li>
      </ol>
    </CompactCard>

    <Heading>Construction Stage</Heading>
    <CompactCard>
      <ol>
        <li><strong>Site Work and Grading:</strong> Level the land, remove debris, and prepare for foundation work.</li>
        <li><strong>Foundation Work:</strong> Lay the foundation, such as a slab, crawl space, or basement.</li>
        <li><strong>Framing:</strong> Construct the frame of the house, including walls and roof.</li>
        <li><strong>Electrical, Plumbing, and HVAC:</strong> Install essential systems.</li>
        <li><strong>Insulation and Drywall:</strong> Insulate walls and install drywall.</li>
        <li><strong>Windows and Doors:</strong> Add windows and doors for a complete structure.</li>
        <li><strong>Roofing:</strong> Install roofing materials like shingles or tiles.</li>
        <li><strong>Siding and Exterior Finishes:</strong> Install siding, trim, and other exterior finishes.</li>
      </ol>
    </CompactCard>

    <Heading>Finishing Stage</Heading>
    <CompactCard>
      <ol>
        <li><strong>Flooring:</strong> Install hardwood, carpet, or tile flooring.</li>
        <li><strong>Cabinets and Countertops:</strong> Fit kitchen and bathroom cabinets and countertops.</li>
        <li><strong>Interior Walls and Ceilings:</strong> Paint, texture, or finish interior surfaces.</li>
        <li><strong>Lighting and Fixtures:</strong> Add lighting and plumbing fixtures.</li>
        <li><strong>Appliances:</strong> Install kitchen appliances.</li>
        <li><strong>Final Inspections and Touch-ups:</strong> Address any issues and ensure quality.</li>
      </ol>
    </CompactCard>

    <Heading>Completion Stage</Heading>
    <CompactCard>
      <ol>
        <li><strong>Certificate of Occupancy:</strong> Obtain final approval from local authorities.</li>
        <li><strong>Final Walk-through:</strong> Review the home with the homeowner.</li>
        <li><strong>Warranty and Maintenance:</strong> Provide essential documentation to the homeowner.</li>
        <li><strong>Project Completion:</strong> Mark the official completion of the project.</li>
      </ol>
    </CompactCard>

    <p>
      <HighlightText>Note:</HighlightText> The procedure may vary depending on factors like location, climate, and local regulations. At <HighlightText>Elegant E Space</HighlightText>, we ensure every step is executed with precision, keeping the client’s vision at the core.
    </p>

    <OfferText>
      <strong>Let us build your dream home!</strong><br />
      Contact us today to discuss your project and get started on your journey to owning a beautifully crafted home.
    </OfferText>
  </Section>
);


// Main Service List
const services = [
  { title: '3-D Elevation Drawing', image: 'https://res.cloudinary.com/dwbiisgio/image/upload/v1729107841/SAVE_20240810_093551_pioftd.jpg', component: <ThreeDElevation /> },
  { title: 'Interior Design', image: 'https://res.cloudinary.com/dwbiisgio/image/upload/v1729109181/SAVE_20240812_241600_qvetfi.jpg', component: <InteriorDesign /> },
  { title: 'Vastu Consultation', image: 'https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/tc8qkfdim1o3gpndmj5x.jpg', component: <VastuConsultation /> },
  { title: 'REAL ESTATE & BUILDERS', image: 'https://res.cloudinary.com/dwbiisgio/image/upload/v1736610694/Messenger_creation_608464235201090.jpeg_u6pplq.jpg', component: <RealEstateBuilders /> },
  { title: 'INTERIOR DECORATION', image: 'https://res.cloudinary.com/dwbiisgio/image/upload/v1736610693/received_1931644560661068.jpeg_wkyhoh.jpg', component: <InteriorDecoration /> },
  { title: 'Residential House Construction', image: 'https://res.cloudinary.com/dwbiisgio/image/upload/v1736610693/Messenger_creation_548610114812435.jpeg_jjrwc0.jpg', component: <ResidentialConstruction /> },

];

function Services() {
  const [selectedService, setSelectedService] = useState(null);

  return (
    <ServicesSection id="services">
      <ServicesTitle>Our Services</ServicesTitle>
      <TitleUnderline />
      <ServiceGrid>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceImage src={service.image} alt={service.title} />
            <ServiceContent>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ViewMoreButton onClick={() => setSelectedService(service)}>
                View More
              </ViewMoreButton>
            </ServiceContent>
          </ServiceCard>
        ))}
      </ServiceGrid>
      {selectedService && (
        <ServiceDetails
          closeModal={() => setSelectedService(null)}
          title={selectedService.title}
          content={selectedService.component}
        />
      )}
    </ServicesSection>
  );
}

export default Services;
